class Carousel {
	constructor(carousel, options = {}) {
		if (!carousel) return;

		const defaults = {
			autorotate: true,
			interval: 5000,
			navContainer: null,
			transitionDuration: 600,
		};

		this.options = { ...defaults, ...options };
		this.carousel = carousel;
		this.slides = Array.from(this.carousel.children);

		if (this.slides.length < 2) return;

		this.amount = this.slides.length;
		this.currentIndex = 0;
		this.realIndex = 0;
		this.indicators = [];

		this.carousel.scrollTo(0, 0);
		this.doTransform(0);
		this.lastIndex = this.slides.length - 1;

		this.carousel.style.overflow = "hidden";
		this.firstClone = this.slides[0].cloneNode(true);
		this.lastClone = this.slides[this.lastIndex].cloneNode(true);
		this.firstClone.setAttribute("aria-hidden", "true");
		this.lastClone.setAttribute("aria-hidden", "true");

		this.carousel.insertAdjacentElement("beforeend", this.firstClone);
		this.carousel.insertAdjacentElement("afterbegin", this.lastClone);

		this.createNavigation();
		//this.setBoundaryClasses();

		this.slides[0].addEventListener("transitionend", () => {
			if (this.realIndex === this.amount) {
				this.removeTransition();
				this.doTransform(0);
				this.realIndex = 0;
				this.currentIndex = 0;
				this.resetRotation();
				return;
			}

			if (this.realIndex === -1) {
				this.removeTransition();
				this.doTransform(this.lastIndex * -100);
				this.realIndex = this.lastIndex;
				this.currentIndex = this.lastIndex;
				this.resetRotation();
				return;
			}
		});

		if (this.options.autorotate) {
			this.rotate = setInterval(() => {
				this.next();
			}, this.options.interval);

			carousel.addEventListener("mouseenter", () => {
				clearInterval(this.rotate);
			});

			carousel.addEventListener("mouseleave", () => {
				this.rotate = setInterval(() => {
					this.next();
				}, this.options.interval);
			});
		}

		this.slideTo(this.currentIndex);
	}

	resetRotation() {
		clearInterval(this.rotate);

		if (this.options.autorotate) {
			this.rotate = setInterval(() => {
				this.next();
			}, this.options.interval);
		}
	}

	createNavigation() {
		const navWrapper = document.createElement("div");
		const arrowsWrapper = document.createElement("div");
		const buttonLeft = document.createElement("button");
		const buttonRight = document.createElement("button");

		navWrapper.className = "carousel__nav";
		arrowsWrapper.className = "carousel__arrows";
		buttonLeft.className = "carousel__button carousel__button--previous";
		buttonRight.className = "carousel__button carousel__button--next";
		buttonLeft.innerHTML =
			'<svg width="8" height="14" viewBox="0 0 8 14"><path d="M7.07,1.28l-4.467,5.36c0,0 4.467,5.36 4.467,5.36l-1.537,1.28l-5.533,-6.64l5.533,-6.64l1.537,1.28Z"/></svg>';
		buttonRight.innerHTML =
			'<svg width="8" height="14" viewBox="0 0 8 14"><path d="M0,1.28l4.467,5.36c-0,0 -4.467,5.36 -4.467,5.36l1.536,1.28l5.534,-6.64l-5.534,-6.64l-1.536,1.28Z"/></svg>';

		buttonLeft.addEventListener("click", () => {
			this.previous();
		});
		buttonRight.addEventListener("click", () => {
			this.next();
		});

		this.slides.forEach((slide, index) => {
			const indicator = document.createElement("span");
			indicator.className = "carousel__indicator";
			this.indicators.push(indicator);
			navWrapper.appendChild(indicator);

			indicator.addEventListener("click", () => {
				this.slideTo(index);
			});
		});

		arrowsWrapper.insertAdjacentElement("afterbegin", buttonLeft);
		arrowsWrapper.insertAdjacentElement("beforeend", buttonRight);

		this.options.navContainer.insertAdjacentElement("beforeend", navWrapper);
		this.options.navContainer.insertAdjacentElement(
			"beforeend",
			arrowsWrapper
		);
	}

	next() {
		this.slideTo(this.currentIndex + 1);
	}

	previous() {
		this.slideTo(this.currentIndex - 1);
	}

	doTransform(amount) {
		Array.from(this.carousel.children).forEach((slide) => {
			slide.style.transform = `translateX(${amount}%)`;
		});
	}

	removeTransition() {
		Array.from(this.carousel.children).forEach((slide) => {
			slide.style.transition = "none";
		});
	}

	setTransition() {
		Array.from(this.carousel.children).forEach((slide) => {
			slide.style.transition = `transform ${this.options.transitionDuration}ms ease-out`;
		});
	}

	slideTo(index) {
		this.realIndex = index;

		if (index === -1 || index === this.amount) {
			const nextIndicatorIndex = index === -1 ? this.lastIndex : 0;
			this.setTransition();
			this.indicators[this.currentIndex].classList.remove(
				"carousel__indicator--current"
			);
			this.indicators[nextIndicatorIndex].classList.add(
				"carousel__indicator--current"
			);
			this.doTransform(index * -100);
			return;
		}

		if (index >= 0 && index < this.slides.length) {
			this.indicators[this.currentIndex].classList.remove(
				"carousel__indicator--current"
			);
			this.currentIndex = index;
			this.indicators[this.currentIndex].classList.add(
				"carousel__indicator--current"
			);

			this.setTransition();
			this.doTransform(this.currentIndex * -100);
			this.resetRotation();
		}
	}
}
